// src/views/pitch.js

import React, { useEffect, useState, useContext } from 'react';
import theme from '../theme';
import ReactPlayer from 'react-player';
import { useNavigate, useLoaderData } from 'react-router-dom';
import { log } from '../utils/logger';

import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Link,
  Typography
} from '@material-ui/core';

import { LocalVotesContext } from '../components/local_votes_context';
import { AuthContext } from '../components/auth_context';
import { isPastQualifier, isCurrentQualifier } from '../utils/qualifiers';

let baseUrl = process.env.REACT_APP_API_HOST;

const useStyles = makeStyles({
  bannerContainer: {
    'a img': {
      width: '100%'
    }
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      '& h1': { padding: theme.spacing(2, 0), fontSize: '1.5rem' }
    }
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardMedia: {
    paddingTop: '56.25%'
  },
  cardContent: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '.5rem'
    }
  },
  playerContainer: {
    height: '360px',
    [theme.breakpoints.down('sm')]: {
      height: '200px'
    }
  },
  founder: {
    margin: theme.spacing(3, 0, 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  instructions: {
    margin: theme.spacing(2, 0, 0)
  },
  profileButton: {
    fontSize: '1.5rem',
    // marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      // marginLeft: theme.spacing(0.5),
      fontSize: '1rem'
    }
  },
  upvoteButton: {
    fontSize: '1.5rem',
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      // padding: theme.spacing(1, 0),
      fontSize: '1rem'
    }
  },
  viewNextStartup: {
    margin: '2em 0',
    fontSize: '1rem',
    textAlign: 'right'
  },
  primeTimeTickets: {
    color: 'white',
    backgroundColor: '#11052E',
    textAlign: 'center',
    '&:hover': { backgroundColor: '#222E05' },
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      fontSize: '.5rem'
    }
  },
  pastQualifier: {
    padding: '.2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '.2rem 0',
      fontSize: '.8rem'
    }
  },
  startupURL: {
    margin: theme.spacing(0, 1, 1)
  }
});

function resolveNextStartup(currentStartup, regionalStartups) {
  // console.log({ currentStartup, regionalStartups });
  let index = Math.floor(Math.random() * (regionalStartups.length - 1));
  return regionalStartups[index];
}

function PitchView(props) {
  const navigate = useNavigate();
  const { pitch, regionData } = props;
  const classes = useStyles();
  const [hasCompletedPitch, setHasCompletedPitch] = useState();
  const [votes, setVotes] = useState([]);
  const [justVoted, setJustVoted] = useState(false);
  const [isFirstVote, setIsFirstVote] = useState(false);
  const [showNextStartup, setShowNextStartup] = useState();

  const { localVotes, setLocalVotes, completedPitches, setCompletedPitches } =
    useContext(LocalVotesContext);

  const { isAuthenticated, authToken, email } = useContext(AuthContext);

  const currentRegion = regionData.regions.find(r => r.name === pitch.regionName);

  // console.log({
  //   pitch,
  //   localVotes,
  //   completedPitches,
  //   isAuthenticated,
  //   authToken,
  //   regionData,
  //   currentRegion
  // });

  if (isFirstVote) {
    window.location.href = 'https://www.founderslive.com/post-vote';
  }

  let nextStartup = resolveNextStartup(
    pitch,
    regionData.regionalQualifierStartups.filter(rqs => rqs.startupKey !== pitch.key)
  );

  async function submitVote(vote) {
    if (isAuthenticated) {
      setLocalVotes({ ...localVotes, [pitch.key]: true });

      log('primetime.pitches.submit_vote', { email, ...vote });

      var result = await fetch(`${baseUrl}/votes`, {
        method: 'POST',
        body: JSON.stringify(vote),
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${authToken}`
        }
      }).then(res => res.json());

      if (result.isFirstVote) {
        setIsFirstVote(true);
      }

      setJustVoted(true);
      setShowNextStartup(true);
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      let baseUrl = process.env.NEXT_PUBLIC_API_HOST;
      fetch(`${baseUrl}/votes`, {
        headers: { authorization: `Bearer ${authToken}` }
      })
        .then(res => res.json())
        .then(data => setVotes(data));
    }
  }, [pitch.slug, authToken, isAuthenticated]);

  let existingVote =
    votes && votes.length && votes.find(v => v.startupKey === pitch.key);

  function redirectToLogin() {
    setCompletedPitches({ ...completedPitches, [pitch.key]: true });
    navigate(`/login?redirect=${window.location.pathname}`);
  }

  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Typography gutterBottom variant="h3" component="h1">
        {pitch.startupName} - {pitch.city}
      </Typography>
      <Typography className={classes.startupURL} component="p">
        <Link target="_blank" href={pitch.startupProfileURL}>
          {pitch.startupProfileURL}
        </Link>
      </Typography>

      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          {(localVotes[pitch.key] || existingVote) && hasCompletedPitch ? (
            <div className={classes.bannerContainer}>
              <a
                href="https://www.founderslive.com/primetime"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  alt="Founders Live Prime Time"
                  style={{ width: '100%' }}
                  src={
                    process.env.REACT_APP_ASSETS_PREFIX +
                    '/images/prime_time_voting_banner_2003.png'
                  }
                />
              </a>
            </div>
          ) : (
            <div className={classes.playerContainer}>
              <ReactPlayer
                className={classes.player}
                width="100%"
                height="100%"
                playing={true}
                url={pitch.pitchVideoURL}
                onProgress={({
                  played /*, playedSeconds, loadedSeconds, loaded  */
                }) => {
                  let percentPlayed = Math.floor(played * 100);

                  if (percentPlayed % 20 === 0 && percentPlayed > 0) {
                  }
                }}
                onEnded={() => {
                  setHasCompletedPitch(true);
                }}
              />
            </div>
          )}

          <Typography className={classes.founder} variant="h5" component="h3">
            Founder: {pitch.startupFounder}
          </Typography>
          <Typography
            className={classes.instructions}
            variant="body1"
            component="p"
          >
            Watch entire pitch video to activate VOTE button
          </Typography>

          {isPastQualifier(
            pitch.regionalQualifierYear,
            pitch.regionalQualifierQuarter
          ) ? (
            <Typography
              className={classes.pastQualifier}
              variant="body1"
              component="p"
            >
              Qualified {pitch.regionalQualifierQuarter}{' '}
              {pitch.regionalQualifierYear}
            </Typography>
          ) : null}
        </CardContent>
        <CardActions>
          <Grid container>
            <Grid item xs={7} md={9}>
              {isCurrentQualifier(
                pitch.regionalQualifierYear,
                pitch.regionalQualifierQuarter
              ) ? (
                localVotes[pitch.key] || existingVote ? (
                  <Button
                    className={classes.upvoteButton}
                    disabled={true}
                    variant="contained"
                  >
                    Voted!
                  </Button>
                ) : (
                  <Button
                    disabled={!(hasCompletedPitch || completedPitches[pitch.key])}
                    className={classes.upvoteButton}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      let voteData = {
                        startupKey: pitch.key,
                        eventType: 'pt-regional-qualifier',
                        eventKey: pitch.regionalQualifierKey
                      };

                      log('primetime.pitches.click_vote', {
                        ...voteData,
                        isAuthenticated
                      });

                      return isAuthenticated
                        ? submitVote(voteData)
                        : redirectToLogin();
                    }}
                  >
                    Vote
                  </Button>
                )
              ) : null}
            </Grid>
            <Grid item xs={5} md={3}>
              <Button
                href="https://www.founderslive.com/primetime"
                target="_blank"
                className={classes.primeTimeTickets}
                variant="contained"
              >
                Get Prime Time <br />
                Tickets
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      {justVoted ? (
        <Typography
          style={{ paddingTop: '1rem' }}
          className={classes.formHeader}
          component="p"
          variant="body1"
        >
          Thanks for voting! Your vote will be tallied shortly.
        </Typography>
      ) : null}

      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            component="p"
            style={{ margin: '2em 0', fontSize: '1rem' }}
          >
            {isPastQualifier(
              pitch.regionalQualifierYear,
              pitch.regionalQualifierQuarter
            ) ? (
              <Link href={`/regions-previous-startups/${currentRegion.slug}`}>
                {'< Back to previous regional startups'}
              </Link>
            ) : (
              <Link href={`/regions/${currentRegion.slug}`}>
                {'< Back to current regional startups'}
              </Link>
            )}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {showNextStartup ? (
            <Typography
              className={classes.viewNextStartup}
              variant="body1"
              component="p"
            >
              <Link href={`/pitches/${nextStartup.startupKey}`}>
                {'View next startup >'}
              </Link>
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
}

export default function Pitch() {
  const { pitch, regionData } = useLoaderData();
  return <PitchView pitch={pitch} regionData={regionData} />;
}
