// src/pages/regions/[key].js

import React, { useState, useEffect } from 'react';
import theme from '../theme';
import { useNavigate, useParams, useLoaderData } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
  Container,
  Divider,
  Grid,
  Link,
  Paper,
  Typography
} from '@material-ui/core';

import { ArrowUpward as ArrowUpwardIcon } from '@material-ui/icons';
import DoneIcon from '@material-ui/icons/Done';
import {
  isCurrentQualifier /*, currentQuarterEndsAt */
} from '../utils/qualifiers';
import { mapEventVotesToRegionPitches } from '../utils/scorekeeper';

const useStyles = makeStyles({
  h1: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: { fontSize: '2rem' }
  },
  h3: { margin: theme.spacing(6, 2) },
  qualifierEndDate: {
    margin: theme.spacing(3.5, 0, 0),
    [theme.breakpoints.down('sm')]: { fontSize: '.6rem' }
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  gridContainer: { margin: theme.spacing(4, 0) },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  startupList: {
    width: '100%',
    margin: theme.spacing(2, 0)
  },
  startupListItem: {
    padding: theme.spacing(2, 1),
    '&:hover': {
      backgroundColor: '#f2f2f2',
      cursor: 'pointer'
    },
    [theme.breakpoints.down('sm')]: {
      '& h5': { fontSize: '1rem' }
    }
  },
  qualifiedIcon: {
    margin: theme.spacing(0, 1, 0, 0),
    paddingTop: theme.spacing(0.5),
    color: '#00b0d1',
    fontSize: 25,
    fontWeight: 900
  },
  legendContainer: {
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '.6rem'
    }
  },
  legendIcon: {
    color: '#00b0d1',
    fontSize: 20,
    margin: theme.spacing(0, 1),
    paddingTop: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0.5),
      fontSize: 15
    }
  }
});

export default function Region() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { regionData } = useLoaderData();
  const [updatedRegionData, setUpdatedRegionData] = useState({});

  let { key } = useParams();

  useEffect(() => {
    let authToken = localStorage.getItem('token');

    if (authToken) {
      fetch(
        `https://sobxi7qsve.execute-api.us-west-2.amazonaws.com/production/regions/${key}`,
        {
          headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${authToken}`
          }
        }
      )
        .then(res => res.json())
        .then(responseBody => {
          if (responseBody && responseBody.pitches) {
            let regionDataMap = {};
            for (var item of responseBody.pitches) {
              regionDataMap[item.key] = item.votes;
            }

            setUpdatedRegionData(regionDataMap);
          }
        })
        .catch(ex => console.error(ex.message));
    }
  }, [key]);

  const { regionalQualifiers, regionalQualifierVotes } = regionData;

  const region = regionData.regions.find(r => r.slug === key);
  const regionPitches = regionData.regionalQualifierStartups.filter(
    r =>
      r.regionKey === key &&
      isCurrentQualifier(r.regionalQualifierYear, r.regionalQualifierQuarter)
  );

  let currentQualifier = regionalQualifiers.find(
    rq => rq.regionKey === key && isCurrentQualifier(rq.year, rq.quarter)
  );

  if (!currentQualifier)
    return (
      <Container className={classes.cardGrid} maxWidth="md">
        <Typography className={classes.h1} variant="h3" component="h1">
          The next regional qualifier will be coming soon!
        </Typography>
      </Container>
    );

  let winnerCount =
    currentQualifier.optionalWinnerCount || region.defaultWinnerCount || 1;

  // console.log({
  //   region,
  //   regionalQualifiers,
  //   currentQualifier,
  //   regionPitches,
  //   regionalQualifierVotes
  // });

  let regionPitchVotes = mapEventVotesToRegionPitches(
    regionPitches,
    regionalQualifierVotes,
    updatedRegionData
  );

  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Typography variant="button" component="p" style={{ fontSize: '1rem' }}>
        <Link href={`/regions`}>{'< Back to regions'}</Link>
      </Typography>
      <Grid container>
        <Grid item md={10} xs={9}>
          <Typography className={classes.h1} variant="h3" component="h1">
            {region.name}
          </Typography>
        </Grid>
        <Grid item md={2} xs={3}>
          <Typography
            className={classes.qualifierEndDate}
            variant="body1"
            component="p"
          >
            {/* Ends {currentQuarterEndsAt()} */}
            Ends Oct 31
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <p className={classes.legendContainer}>
            <DoneIcon className={classes.legendIcon} />
            Currently qualified for Prime Time main event
          </p>
        </Grid>
      </Grid>

      {regionPitchVotes && regionPitchVotes.length ? (
        <Paper variant="outlined" className={classes.startupList}>
          {regionPitchVotes
            .sort((a, b) => b.votes - a.votes)
            .map((rp, index) => (
              <React.Fragment key={rp.startupKey}>
                <Grid
                  className={classes.startupListItem}
                  container
                  onClick={() => navigate(`/pitches/${rp.startupKey}`)}
                >
                  <Grid item xs={10} md={11}>
                    <Typography variant="h5" component="h5">
                      {index < winnerCount && rp.votes ? (
                        <DoneIcon className={classes.qualifiedIcon} />
                      ) : null}
                      {rp.startupName} - {rp.startupCity}, {rp.startupCountry}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} md={1}>
                    <div className={classes.voteTallyContainer}>
                      {' '}
                      <ArrowUpwardIcon style={{ fontSize: '1.2rem' }} />
                      <span style={{ fontSize: '1.3rem' }}> {rp.votes || 0}</span>
                    </div>
                  </Grid>
                </Grid>
                <Divider />
              </React.Fragment>
            ))}
        </Paper>
      ) : (
        <Typography className={classes.h3} variant="h5" component="h3">
          Startup pitches coming soon...
        </Typography>
      )}
    </Container>
  );
}

export async function getServerSideProps() {
  let baseCDNUrl = process.env.REACT_APP_CDN_HOST;

  let responses = await Promise.all([
    fetch(`${baseCDNUrl}/queries/list_regions`),
    fetch(`${baseCDNUrl}/queries/list_regional_qualifiers`),
    fetch(`${baseCDNUrl}/queries/list_regional_qualifier_startups`)
  ]);

  let data = await Promise.all(responses.map(r => r.json()));

  if (!data) {
    return {
      notFound: true
    };
  }

  return {
    props: {
      regionData: {
        regions: data[0],
        regionalQualifiers: data[1],
        regionalQualifierStartups: data[2]
      }
    }
  };
}
